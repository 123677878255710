import { Injectable, Inject, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { IResultHttp } from '../interfaces/IHttpResult';
import { AddressModel } from '../models/AddressModel';

@Injectable({ providedIn: 'root' })
export class AddressService extends BaseService<any> {

    constructor(
        @Inject(Injector) injector: Injector
    ) {
        super('address', injector);
    }

    async getAddress(zipcode: string) {
        const { success, data } = await this.http.get<IResultHttp<AddressModel>>(`${environment.api_url}/address/${zipcode}`).toPromise();
        if (success)
            return data;
        else
            return undefined;
    }

    async getAllStates() {
        const { success, data } = await this.http.get<IResultHttp<any>>(`${environment.api_url}/address`).toPromise();
        if (success)
            return data;
        else
            return undefined;
    }

    async getAllCities(uf: string) {
        const { success, data } = await this.http.get<IResultHttp<any>>(`${environment.api_url}/address/${uf}/cities`).toPromise();
        if (success){
            return data;
        }
        else
            return undefined;
    }
}