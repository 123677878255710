export class AddressModel {
    street: string
    number: string
    neighborhood: string
    city: string
    state: string
    zipCode: string
    complement: string
    latitude: number
    longitude: number
}